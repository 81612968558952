import {Scene} from "./Scene";
import {WordWithBlob} from "./WordWithBlob";


export const obfuscatedQuestionString = (scene: Scene): string => {
    const asked = scene.asked
    const answersSimilar = theyAreSimilar(asked, scene.answers)
    if (asked.langName === "uk" && !answersSimilar) {
        return asked.string.replace(/[0-9a-zA-Z]/g, "\u{25A7}")
    } else {
        return asked.string
    }
}

const theyAreSimilar = (tested: WordWithBlob, toCompare: WordWithBlob[]): boolean => {
    return toCompare.some(x => areSimilar(x.string, tested.string))
}

const areSimilar = (a: string, b: string): boolean => {
    const range = Math.min(a.length, b.length) / 2
    const beginning = (str: string) => str.slice(0, range)
    // .replace('ä', 'a') narazie tylko uk jest kwadracikowany
    // .replace('ü', 'u')
    // .replace('ö', 'o')

    return beginning(a) === beginning(b)
}


