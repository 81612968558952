import {useEffect, useState} from "react";
import {BigWriting, CenteredFlexStyle, PanelsFlex, PictureCardStyle} from "./StyledComponents";
import {AnswerCard} from "./answerCard";
import {screenShot} from "./screenshoting";
import {User} from "./shared-modules/sharedTypes";
import {obfuscatedQuestionString} from "./obfuscation";
import {LearningCheatSurface} from "./LearningCheatSurface";
import {random, sorted} from "./shared-modules/funkcje";
import {inDevelopment} from "./frontendSpecificFunctions";
import {Scene} from "./Scene";
import {WordWithBlob} from "./WordWithBlob";

type ChoiceState = {
    readonly started: number,
    readonly spoken: number,
    readonly answeredGood: boolean,
}

function makeFreshChoiceState(): ChoiceState {
    return {
        started: Date.now(),
        spoken: 0,
        answeredGood: true,
    }
}


export function SceneView({scene, user, whenHit, whenError, afterError}: {
                              scene: Scene,
                              user: User,
                              whenHit: (s: Scene) => void,
                              whenError: () => void,
                              afterError: (s: Scene) => void
                          }
) {

    const [state, setState] = useState(makeFreshChoiceState())

    useEffect(() => {
        scene.audioObject.play().then()
        setState(makeFreshChoiceState())
    }, [scene])


    function reallyCheck(chosenString: string) {
        let textOnScreenShot =
            (Date.now() - state.started).toString() + "\n" +
            scene.asked.string + "\n" +
            chosenString
        if (state.spoken > 0) {
            textOnScreenShot = "spoken: " + state.spoken.toString() + "\n\n" + textOnScreenShot
        }
        if (chosenString === scene.match) {
            screenShot(user.username, 'good-answer', textOnScreenShot, 'green').then()
            scene.audioObject.pause()
            whenHit(scene)
        } else {
            setTimeout(() => screenShot(user.username, 'bad-answer', textOnScreenShot, 'red'), 100)
            const errorState: ChoiceState = {...state, answeredGood: false, started: Date.now()}
            whenError()
            setState(errorState)
        }
    }

    function checkFunc(chosenString: string) {
        if (state.answeredGood) {
            return reallyCheck(chosenString)
        } else {
            return goOnAfterWrongAnswer(chosenString)
        }
    }


    async function goOnAfterWrongAnswer(chosenString: string): Promise<void> {
        if (chosenString === scene.match) {
            // screenShot(user.username, 'after', `${Date.now() - state.started} - click after error`, 'black').then()
            return afterError(scene)
        } else {
            screenShot(user.username, 'click on red', `click on red card`, 'pink').then()
        }
    }


    function cardBgColor(cardsString: string) {
        if (cardsString === scene.match) {
            return "white"
        } else if (state.answeredGood) {
            return "white"
        } else {
            return "red"
        }
    }

    const obfuscatedString = state.answeredGood ? obfuscatedQuestionString(scene) : scene.asked.string


    function speakAgain() {
        scene.audioObject.play().then()
        setState({...state, spoken: state.spoken + 1})
    }

    function sortByLastLetter(panels: WordWithBlob[]) {
        return sorted(panels, (a) => a.string.charCodeAt(a.string.length - 1))
    }

    return (
        <>
            <CenteredFlexStyle onClick={speakAgain}>
                {scene.asked.imgBlob &&
                    <PictureCardStyle>
                        <img src={URL.createObjectURL(scene.asked.imgBlob)} alt={"Question card"}/>
                    </PictureCardStyle>
                }
                {!scene.asked.imgBlob &&
                    <BigWriting>
                        {obfuscatedString}
                    </BigWriting>
                }
            </CenteredFlexStyle>
            <PanelsFlex>
                {
                    sortByLastLetter(random.shuffled(scene.answers, scene.seed))
                        .map((content, i) =>
                            <AnswerCard key={i}
                                        content={content}
                                        callback={() => checkFunc(content.string)}
                                        bg={cardBgColor(content.string)}
                            />
                        )
                }
            </PanelsFlex>
            {inDevelopment(user) && <LearningCheatSurface scene={scene} checkAnswer={checkFunc}/>}
        </>
    )
}
