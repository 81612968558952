import {AudioObject} from "./audioObject";
import {fetchBlob, makeWordWithBlob, WordWithBlob} from "./WordWithBlob";
import {PairWithStats, TopicWithStats} from "./shared-modules/materialTypes";
import {random} from "./shared-modules/funkcje";
import {isSimilarToOneOf} from "./findConflict";
import {makeQuestionsBySimilarity} from "./makeQuestionsBySimilarity";


export type Scene = {
    readonly key: string
    readonly seed: number
    readonly comment: string
    readonly weight: number
    readonly match: string
    readonly hits: number,
    readonly errors: number,
    readonly audioObject: AudioObject
    readonly asked: WordWithBlob,
    readonly answers: WordWithBlob[]
}

export async function makeScene(pair: PairWithStats, topic: TopicWithStats): Promise<Scene> {

    let key = pair.id
    let shownWord = pair.shownWord
    let match = pair.goodAnswer.string
    let answerWords = [pair.goodAnswer]


    const woutIncomplete = topic.pairs.filter(p => p !== pair)
    const candidates = random.shuffled(woutIncomplete, pair.seed)

    function randomBads(howMuch: number): PairWithStats[] {
        const bads = []
        for (const candidate of random.shuffled(candidates, pair.seed)) {
            if (bads.length < howMuch && !isSimilarToOneOf(candidate, [pair, ...bads])) {
                bads.push(candidate)
            }
        }
        return bads
    }


    if (topic.type === "irreversible") {
        const bads = makeQuestionsBySimilarity(pair, candidates).map(b => b.goodAnswer)
        answerWords.push(...bads)
    } else {
        const reverseProb = shownWord.picFilename === undefined ? 0.6 : 0.2
        if (random.bool(reverseProb, topic.seed)) {
            shownWord = pair.goodAnswer
            match = pair.shownWord.string
            answerWords = [pair.shownWord, ...randomBads(1).map(b => b.shownWord)]
        } else {
            const numOfBads = pair.goodAnswer.picFilename === undefined ? 1 : 2
            answerWords.push(...randomBads(numOfBads).map(p => p.goodAnswer))
        }
    }

    //new (old) code after hunt bug
    const audioObject = new AudioObject(await fetchBlob(`/dzwieki/${shownWord.soundFilename}`))
    const asked = await makeWordWithBlob(shownWord)
    const answers = []
    for (const word of answerWords) {
        answers.push(await makeWordWithBlob(word))
    }


    return {
        key,
        seed: pair.seed,
        comment: pair.comment,
        weight: pair.weight,
        match,
        hits: 0,
        errors: 0,
        audioObject,
        asked,
        answers,
    }
}



